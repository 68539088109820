<template>
  <div class="page-div">
    <page-navigation-bar title="抽奖记录" />
    <div class="page-content-div">
      <div class="item-div" v-for="item in items" :key="item.id">
        <div>
          <div class="title-label">抽奖</div>
          <div class="time-label">{{ item.createTime }}</div>
        </div>
        <div v-if="item.state === 0" class="remarks-div">未中奖</div>
        <div v-else class="remarks-div mark-div">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import PageNavigationBar from "../components/PageNavigationBar";
import { getLuckDrawItems } from "../common/api";

export default {
  name: "AwardNotes",
  components: { PageNavigationBar },
  data() {
    return {
      items: []
    };
  },
  created() {
    this.initItems();
  },
  methods: {
    initItems() {
      getLuckDrawItems().then(data => {
        if(Array.isArray(data)){
          this.items = data;
        }else{
          this.items = [];
        }
      }).catch(error => {
        this.$layer.msg("查询抽奖记录失败，请稍后重试");
        this.$router.back();
      });
    }
  }
}
</script>

<style lang="less" scoped>
.page-div {
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  display: flex;
  flex-direction: column;
  .page-content-div {
    width: 95%;
    flex: 1;
    margin: 0.1rem auto 0.1rem auto;
    padding-bottom: 0.1rem;
    border-radius: 0.1rem;
    background-color: #FFFFFF;
    .item-div {
      width: 95%;
      margin: 0.1rem auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .title-label {
        font-size: 0.15rem;
      }
      .time-label {
        margin-top: 0.02rem;
        color: #ACACAC;
        font-size: 0.14rem;
      }
      .remarks-div {
        font-size: 0.15rem;
        font-weight: bold;
      }
      .mark-div {
        color: #DD4F42;
      }
    }
  }
}
</style>